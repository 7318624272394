@import 'styles/variables.scss';
.setting {
  .incident-management {
    margin-left: 2 * $space-size-base;
    .ant-tabs-nav-wrap {
      border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #18c9f3 !important;
    }
    .ant-tabs-ink-bar {
      background: #18c9f3;
    }
    .ant-tabs-tab-btn {
      font-style: normal;
      padding-left: 16px;
      padding-right: 16px;

      font-size: $font-size-17;
      line-height: $line-height-rate-base;
      /* identical to box height, or 25px */

      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.1px;

      &:hover {
        color: #18c9f3;
      }
    }
    .list-incident-management {
      .header-list {
        height: 48px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-shadow: inset 0px -1px 0px rgba(144, 147, 153, 0.2);
        .title {
          margin-left: 50px;
          font-weight: normal;
          font-size: $font-size-14;
          color: #343434;
          text-transform: uppercase;
        }
        .add {
          margin-right: 20px;

          .btn-add {
            span:nth-child(2) {
              font-style: normal;

              font-size: $font-size-15;
              line-height: $line-height-rate-nano;
              /* identical to box height, or 18px */

              /* System / White */

              color: #ffffff;
            }
          }
        }
      }
      .body {
        padding-bottom: 70px;
        height: calc(100vh - 280px);
        overflow-y: scroll;

        .wrap-list {
          list-style: none;
          padding: 0px;
          padding-right: 20px;

          .left {
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
}

.modal-incident-attr-crud {
  .input-name {
    font-style: normal;
    font-weight: normal;
    font-size: $font-size-15;
    color: #343434;
  }

  .select-family {
    .ant-select-selector {
      border-radius: 0.5 * $space-size-base;
      .ant-select-selection-item {
        font-style: normal;
        font-weight: normal;
        font-size: $font-size-15;
        color: #343434;
      }
    }
  }
}
