@import 'styles/variables.scss';

.notifications {
  width: 520px;
  background: #ffffff;
  padding: 0;
  padding-left: 2 * $space-size-base;
  margin: 0 auto;

  table > th {
    font-weight: 600;
  }

  .header-notifications {
    height: 82px;
    padding: 2 * $space-size-base 0;
    background: #ffffff;
    box-shadow: inset 0px -1px 0px rgba(144, 147, 153, 0.2);
    display: flex;
    align-items: center;

    .txt-notifications {
      font-style: normal;

      font-size: $font-size-21;
      line-height: $line-height-rate-micro;
      letter-spacing: 0.2px;
      color: #343434;
      margin: 0px 10px;
    }
  }
  .all-notifications {
    .ant-list-item {
      background: #ffffff;
      box-shadow: inset 0px -1px 0px rgba(144, 147, 153, 0.2);
      cursor: pointer;
      min-height: 72px;
      border-bottom: none;
      padding-right: 16px;

      .ant-list-item-meta {
        .ant-list-item-meta-title {
          width: 100%;
          font-style: normal;
          display: block;

          font-size: $font-size-15;
          line-height: $line-height-rate-nano;
          align-items: center;
          letter-spacing: 0.1px;
          color: #343434;

          .incident-number {
            margin-top: 6px;
            position: static;
            font-style: normal;
            font-weight: normal;
            font-size: $font-size-15;
            line-height: $line-height-rate-base;
            letter-spacing: 0.1px;
            color: #007aff;
          }

          .message {
            padding-right: 40px;
          }

          .ant-space-vertical {
            display: block;
          }
        }

        .ant-list-item-meta-avatar {
          .avatar {
            background: #f2f2f2;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: unset;
              height: unset;
            }
          }
        }

        .ant-list-item-meta-description {
          font-style: normal;
          font-weight: normal;
          font-size: $font-size-15;
          line-height: $line-height-rate-nano;
          letter-spacing: 0.1px;
          color: #c6c6c8;
        }
      }

      .is-read {
        width: 1.5 * $space-size-base;
        height: 1.5 * $space-size-base;
        background: #18c9f3;
        border-radius: 1.5 * $space-size-base;
      }
    }
  }
}
