@import 'styles/variables.scss';

.md-other-incident-user {
  .ant-modal-body {
    overflow-y: auto;
    max-height: 600px;
  }
  .table-other-incident-user {
    .ant-table-thead > tr > th {
      &::before {
        display: none;
      }
      font-style: normal;
      font-weight: normal;
      font-size: $font-size-14;
      line-height: $line-height-rate-nano;
      padding: $space-size-base;
      /* or 13px */

      letter-spacing: 0.1px;
      text-transform: uppercase;

      /* Text / Primary */

      color: #343434;
      background: #fff;
    }

    .ant-table-tbody > tr {
      height: 40px;
    }

    .ant-table-tbody > tr > td.incident-sequence {
      padding-right: 24px;
      text-align: right;
    }

    .ant-table-tbody > tr > td {
      font-style: normal;
      font-weight: normal;
      font-size: $font-size-15;
      line-height: $line-height-rate-base;
      /* identical to box height, or 25px */
      padding: 0 1.5 * $space-size-base;

      letter-spacing: 0.1px;

      /* Text / Primary */

      color: #343434;

      .txt-incident {
        color: #007aff;
      }
    }

    td.ant-table-column-sort {
      background: unset;
    }

    .ant-table-row {
      &:hover {
        td {
          // font-weight: 500 !important;
          cursor: pointer;
        }
      }
    }

    .row-odd {
      background: rgba(144, 147, 153, 0.1);
    }

    .row-bold {
      font-weight: 700;
    }
  }
}
