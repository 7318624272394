@import 'styles/variables.scss';

.broadcast {
  .layout-broadcast {
    .header-broadcast {
      padding: 0;
      height: 60px;
      display: flex;
      align-items: center;
      /*position: fixed;*/
      z-index: 99;
      width: 100%;
      background: #f9f9f9;
      /* Inner Shadow / Cell Table */

      box-shadow: inset 0px -1px 0px rgba(144, 147, 153, 0.2);

      .header-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 0 2 * $space-size-base;
        padding-left: 5px;
        .txt-broadcast {
          font-style: normal;
          font-weight: normal;
          font-size: $font-size-17;
          line-height: $line-height-rate-base;
          letter-spacing: 0.1px;
          color: #343434;
          margin: 0px $space-size-base;
        }
        .broadcast-action {
          gap: 1.5 * $space-size-base;
          display: flex;
          align-items: center;
          .btn-add-broadcast {
            min-width: 171px;
            border-radius: 0.5 * $space-size-base;
            font-style: normal;

            font-size: $font-size-15;
            line-height: $line-height-rate-nano;
            color: #ffffff;
          }
        }
      }
    }
    .content-broadcast {
      padding: 0 2 * $space-size-base;
      background-color: #fff;

      .table-broadcast {
        .ant-table-thead > tr > th {
          &::before {
            display: none;
          }
          font-style: normal;
          font-weight: normal;
          font-size: $font-size-14;
          line-height: $line-height-rate-nano;
          /* or 13px */

          letter-spacing: 0.1px;
          text-transform: uppercase;

          /* Text / Primary */

          color: #343434;
          background: #fff;
        }
        .ant-table-tbody {
          &:hover {
            cursor: pointer;
          }
        }
        .ant-table-tbody > tr > td {
          font-style: normal;
          font-weight: normal;
          font-size: $font-size-15;
          /* identical to box height, or 25px */
          padding: 1.5 * $space-size-base;

          letter-spacing: 0.1px;

          /* Text / Primary */

          color: #343434;

          .public {
            background-color: #ff3b30;
          }
          .public.ant-switch-checked {
            background-color: #34c759;
          }
        }
      }
      .schedule {
        .title-schedule {
          display: flex;
          font-style: normal;
          font-weight: normal;
          font-size: $font-size-15;
          line-height: 5px;
          color: #343434;
        }
        .txt-schedule {
          font-style: normal;
          font-weight: normal;
          font-size: $font-size-13;
          letter-spacing: 0.1px;
          color: #343434;
        }
      }
    }
  }
}

.md-form-broadcast,
.md-detail-broadcast {
  .ant-modal-title {
    font-style: normal;

    font-size: $font-size-21;
    line-height: $line-height-rate-micro;
    letter-spacing: 0.2px;
    color: #343434;
  }

  .ant-modal-body {
    padding: 0;
    margin: 3 * $space-size-base;
    position: relative;

    .ant-form-item {
      margin-bottom: 2 * $space-size-base;
    }
  }

  .ant-form-item-label > label {
    font-style: normal;

    font-size: $font-size-15;
    line-height: $line-height-rate-nano;
    letter-spacing: 0.1px;
    color: #343434;
  }

  .inp-subject {
    align-items: center;
    padding: $space-size-base 1.5 * $space-size-base;
    height: 40px;
    box-sizing: border-box;
    border-radius: 0.5 * $space-size-base;
    margin: 0.5 * $space-size-base 0px;
    position: static;
    font-style: normal;
    font-weight: normal;
    font-size: $font-size-15;
    line-height: $line-height-rate-nano;
  }

  .picker-schedule {
    border-radius: 0.5 * $space-size-base;
    height: 40px;
    width: 20rem;

    padding-left: 4px;
    font-size: $font-size-15;

    .ant-picker-input {
      border-radius: 0.5 * $space-size-base;
      height: 40px;
      width: 20rem;
      flex-direction: row-reverse;
      justify-content: flex-end;

      .schedule-clock-container {
        width: 130px;
        margin-right: 8px;
        img.schedule-clock-icon {
          width: 20px;
          margin-right: 12px;
        }
        span.schedule-clock-text {
          vertical-align: middle;
          font-size: $font-size-15;
          color: black;
        }
      }

      input {
        font-style: normal;

        font-size: $font-size-15;
        line-height: $line-height-rate-nano;
        width: 12rem;

        color: #343434;
      }

      .ant-picker-suffix,
      .ant-picker-clear {
        font-size: $font-size-20;
        right: 8px;
      }
    }

    .calendar {
      font-style: normal;
      font-weight: normal;
      font-size: $font-size-14;
      line-height: $line-height-rate-base;
      border-radius: 0.5 * $space-size-base 0px 0px 0.5 * $space-size-base;
    }
  }

  .ant-form-item {
    .select-organization {
      .ant-select-selector {
        align-items: center;
        padding: $space-size-base 1.5 * $space-size-base;
        //width: 752px;
        height: 40px;
        background: #ffffff;
        box-sizing: border-box;
        border-radius: 0.5 * $space-size-base;
        margin: 0.5 * $space-size-base 0px;
        font-style: normal;
        font-weight: normal;
        font-size: $font-size-15;
        line-height: $line-height-rate-nano;
      }
    }
  }
  .inp-message {
    align-items: flex-start;
    padding: $space-size-base 1.5 * $space-size-base;
    height: 124px;
    box-sizing: border-box;
    border-radius: 0.5 * $space-size-base;
    margin: 0.5 * $space-size-base 0px;
    font-style: normal;
    font-weight: normal;
    font-size: $font-size-15;
    line-height: $line-height-rate-nano;
  }

  .preview-file-upload {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px dashed #007aff;
    background: #ebf5ff;
    z-index: 1000;
  }

  .preview-file-upload-hidden {
    display: none !important;
  }

  .upload-broadcast {
    border: 1px solid transparent;
    background: rgba(64, 158, 255, 0.1);
    border-radius: 0.5 * $space-size-base;
    min-height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2 * $space-size-base;
    cursor: pointer;

    .txt-drop,
    .txt-browse {
      font-style: normal;
      font-weight: normal;
      font-size: $font-size-13;
      line-height: $line-height-rate-base;
      /* identical to box height, or 25px */

      display: flex;
      align-items: flex-end;
      letter-spacing: 0.1px;

      color: #000000;
    }

    .txt-drop {
      margin: 0 $space-size-base;
    }

    .txt-browse {
      color: #007aff;
    }
  }

  .all-files {
    max-height: 200px;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .space-file {
    justify-content: space-between;
    width: 100%;
    .ant-space-item:nth-child(2) {
      margin-right: auto;
    }
    .box-file-name {
      width: fit-content;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .file-name {
      font-style: normal;
      font-weight: normal;
      font-size: $font-size-15;
      line-height: $line-height-rate-base;
      /* identical to box height, or 25px */

      letter-spacing: 0.1px;

      /* Text / Primary */

      color: #343434;
    }

    .file-name.error {
      color: #ff3b30;
    }

    .ic-cancel {
      cursor: pointer;
    }
  }
}

.md-detail-broadcast {
  .title-modal-detail {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .btn-save {
    &:disabled {
      background: #c6c6c8;
      color: #fff;
    }
  }
  .select-organization {
    .ant-select-selector {
      align-items: center;
      padding: $space-size-base 1.5 * $space-size-base;
      height: 40px;
      background: #ffffff;
      box-sizing: border-box;
      border-radius: 0.5 * $space-size-base;
      margin: 0.5 * $space-size-base 0px;
      font-style: normal;
      font-weight: normal;
      font-size: $font-size-15;
      line-height: $line-height-rate-nano;
    }
  }

  .select-organization.ant-select-disabled {
    .ant-select-selector {
      color: #343434;
      background: rgba(144, 147, 153, 0.1);
    }
  }

  .inp-subject,
  .inp-message {
    &:disabled {
      color: #343434;
      background: rgba(144, 147, 153, 0.1);
    }
  }

  .attachment {
    width: 100%;
    margin-bottom: 20px;
    .ant-space-horizontal {
      width: 100%;
    }

    .all-files {
      max-height: 300px;
      overflow-y: scroll;
      overflow-x: hidden;
    }
    .space-file {
      position: relative;
      line-height: 60px;
      padding: 9px;
      .file-img {
        width: 100%;
        height: 100%;
        margin-top: 18px;
      }
    }
    .file-name {
      font-style: normal;
      font-weight: normal;
      font-size: $font-size-15;
      color: #343434;
      align-items: flex-start;
      max-width: 500px;
    }
    .file-createdAt {
      position: absolute;
      left: 0px;
      // top: 45px;
      font-style: normal;
      font-weight: normal;
      font-size: $font-size-13;
      line-height: $line-height-rate-nano;
      letter-spacing: 0.1px;
      color: #828282;
      min-width: 150px;
    }
    .file-preview {
      cursor: pointer;
      position: absolute;
      right: -32px;
      top: 0;
    }
    .file-uploaded {
      position: absolute;
      left: 50px;
      top: 37px;
      font-style: normal;
      font-weight: normal;
      font-size: $font-size-13;
      color: #828282;
      line-height: $line-height-rate-nano;
    }
  }

  .radio-resend {
    .ant-radio-wrapper {
      padding: 7px;
      min-width: 346px;

      span {
        &:nth-child(2) {
          font-style: normal;
          font-weight: normal;
          font-size: $font-size-15;
          line-height: $line-height-rate-base;
          letter-spacing: 0.1px;

          /* Primary Text */

          color: #343434;
        }
      }
    }

    .ant-radio-wrapper-checked {
      border-radius: 0.5 * $space-size-base;
      border: 1px solid #007aff;
      background: rgba(64, 158, 255, 0.1);
      .ant-radio-checked .ant-radio-inner {
        border-color: #007aff;
        &:after {
          background-color: #007aff;
        }
      }
    }
  }

  .sub-title {
    font-style: normal;

    font-size: $font-size-15;
  }
  .btn-del-broadcast {
    width: 183px;
    height: 40px;
    background: #ffffff;
    border: 1px solid #ff3b30;
    border-radius: 0.5 * $space-size-base;
    margin: 0px 3 * $space-size-base;
    font-style: normal;

    font-size: $font-size-15;
    line-height: $line-height-rate-nano;
    color: #ff3b30;
  }
  .label-info {
    margin-top: 18px;
    .ant-space-item {
      .txt {
        font-style: normal;

        font-size: $font-size-15;
        line-height: $line-height-rate-base;

        color: #007aff;
        text-transform: uppercase;
      }
      .ic-info {
        color: rgba(0, 0, 0, 0.54);
        font-size: $font-size-20;
        margin-top: 5px;
      }
    }
  }

  .div-label-info {
    margin-top: 1.5 * $space-size-base;
  }

  .table-activity-log {
    .ant-table-thead > tr > th {
      &::before {
        display: none;
      }
      font-style: normal;
      font-weight: normal;
      font-size: $font-size-14;
      line-height: $line-height-rate-nano;
      /* or 13px */

      letter-spacing: 0.1px;
      text-transform: uppercase;

      /* Text / Primary */

      color: #343434;
      background: #fff;

      .btn-new-user {
        width: 131px;
        height: 32px;

        span:nth-child(2) {
          font-style: normal;

          font-size: $font-size-15;
        }
      }
    }

    .ant-table-tbody > tr > td {
      font-style: normal;
      font-weight: normal;
      font-size: $font-size-15;
      line-height: $line-height-rate-nano;
      align-items: center;
      letter-spacing: 0.5px;
      color: #343434;
      padding: 1.5 * $space-size-base;
    }
    .status-title {
      display: inline-block;
      width: 100%;
      font-style: normal;
      font-weight: normal;
      font-size: $font-size-15;
      line-height: $line-height-rate-nano;
      letter-spacing: 0.5px;
      color: #343434;
    }
    .status-value {
      font-style: normal;
      font-weight: normal;
      font-size: $font-size-13;
      line-height: $line-height-rate-nano;
      letter-spacing: 0.1px;
      color: #343434;
    }

    .txt-schedule {
      font-style: normal;
      font-weight: normal;
      font-size: $font-size-13;
    }
  }
}
