@import 'styles/variables.scss';

.layout {
  height: 100%;
  .ant-input,
  .ant-select,
  .ant-btn {
    border-radius: 0.5 * $space-size-base;
  }

  .ant-input,
  .ant-select-selection-item {
    font-style: normal;
    font-weight: normal;
    font-size: $font-size-15;
    line-height: $line-height-rate-base;
    /* identical to box height, or 25px */

    letter-spacing: 0.1px;

    /* Text / Primary */

    color: #343434;
  }
  background: #fff !important;

  .ant-layout-content {
    height: 100%;
    padding-bottom: 48px;
  }

  .content-notifications {
    background: #fbfbfb;
    height: calc(100vh - 60px);
  }

  .footer {
    z-index: 99;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3 * $space-size-base 2 * $space-size-base;

    background: #f9f9f9;
    box-shadow: inset 0px 1px 0px rgba(144, 147, 153, 0.1);
    position: fixed;
    bottom: 0;
    width: 100%;
    .ant-typography {
      cursor: pointer;
      font-style: normal;
      font-weight: normal;
      font-size: $font-size-14;
      line-height: $line-height-rate-nano;
      letter-spacing: 0.5px;
      color: #2f80ed;
    }

    .txt-version {
      color: #343434;
    }
  }
}
