@import 'styles/variables.scss';

.sos-setup {
  .table-sos {
    padding-top: 2 * $space-size-base;

    .ant-table-thead > tr > th {
      &::before {
        display: none;
      }
      font-style: normal;
      font-weight: normal;
      font-size: $font-size-14;
      line-height: $line-height-rate-nano;
      /* or 13px */

      letter-spacing: 0.1px;
      text-transform: uppercase;

      /* Text / Primary */

      color: #343434;
      background: #fff;

      .ic-info {
        color: rgba(0, 0, 0, 0.54);
      }

      .btn-add-sos {
        span:nth-child(2) {
          font-style: normal;

          font-size: $font-size-15;
          line-height: $line-height-rate-nano;
        }
      }
    }

    .ant-table-body {
      overflow-x: hidden;
      /*max-height: calc(50px * 10) !important;*/
    }

    .ant-table-tbody > tr > td {
      font-style: normal;
      font-weight: normal;
      font-size: $font-size-15;
      line-height: $line-height-rate-base;
      /* identical to box height, or 25px */
      padding: 1.5 * $space-size-base;

      letter-spacing: 0.1px;

      /* Text / Primary */

      color: #343434;
      height: 50px;
      .ic-delete,
      .ic-edit {
        cursor: pointer;
      }
    }
  }
}

.md-form-sos {
  .input-name {
    font-style: normal;
    font-weight: normal;
    font-size: $font-size-15;
    color: #343434;
  }

  .radio-sos {
    width: 100%;
    .space-radio-sos {
      width: 100%;

      .ant-space-item {
        width: 100%;

        .cb-values {
          width: 100%;
          padding: 10px;
          border: 1px solid #e0e0e0;
          box-sizing: border-box;
          border-radius: 0.5 * $space-size-base;
        }

        .ant-radio-wrapper-checked {
          border-radius: 0.5 * $space-size-base;
          background: rgba(64, 158, 255, 0.1);
          border: 1px solid #007aff;
        }

        .radio-title {
          margin-bottom: 6px;
        }

        .input-value {
          margin-top: 10px;
          width: 520px;
          font-style: normal;
          font-weight: normal;
          font-size: $font-size-15;
          color: #343434;
        }
      }
    }
  }
}

.sos-item-drag {
  display: flex;
  td:first-child {
    width: 48px;
  }
  td:nth-child(2) {
    width: 430px;
    min-width: 430px;
  }
  td:nth-child(3) {
    width: 230px;
  }
  td:nth-child(4) {
    width: 230px;
  }
  td:nth-child(5) {
    /*width: 230px;*/
    width: calc(100vw - 940px);
  }
}
