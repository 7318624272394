@import 'styles/variables.scss';

.login-page {
  min-height: calc(100vh - 15px);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  .lock-block {
    .maintenance-icon {
      margin-bottom: 32px;
    }
    button {
      .btn-ok-2 {
        margin-top: 32px;
      }
    }
  }
  .form-tow-factor {
    position: relative;
    .ant-form-item-explain-error {
      transform: initial;
    }
    
    .ant-space-align-center {
      align-items: initial;
    }
  }

  .card-form {
    position: absolute;
    width: 567px;
    min-height: 640px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: $space-size-base;
    padding: 56px;

    .ant-card-body {
      padding: 0;
    }

    /* Login Form */

    .space-login {
      width: 100%;
    }

    .input-email,
    .input-password {
      height: 54px;
      input {
        font-style: normal;
        font-weight: normal;
        font-size: $font-size-17;
        line-height: $line-height-rate-base;
        /* identical to box height, or 25px */

        letter-spacing: 0.1px;

        /* Text / Primary */

        color: #343434;
      }

      .ant-input-suffix {
        margin-bottom: -3px;
      }
    }

    .ant-form-item-label {
      padding-bottom: 2px;
    }

    .ant-form-item-required {
      font-style: normal;

      font-size: $font-size-15;
      line-height: $line-height-rate-nano;
      /* identical to box height, or 18px */

      display: inline-flex;
      align-items: center;
      letter-spacing: 0.1px;

      flex-direction: row-reverse;
      position: relative;
      /* Text / Primary */

      color: #343434;
      .ant-form-item-tooltip {
        position: absolute;
        top: 1px;
        right: -16px;
        z-index: 10;
      }
      &::before {
        margin-left: 0.5 * $space-size-base;
      }
    }

    .ant-input-affix-wrapper {
      border-radius: 0.5 * $space-size-base;
    }

    .ant-form-item-has-success {
      .ant-input-affix-wrapper {
        border-color: #34c759;

        .ant-input-clear-icon {
          display: none;
        }
      }
    }

    .ant-input-clear-icon,
    .ant-form-item-children-icon {
      font-size: $font-size-18;
    }

    .logo {
      margin-bottom: 40px;
      margin-top: 30px;
      text-align: center;
    }

    .login-form-forgot {
      float: right;
      margin-top: -105px;
      padding-right: 0;
      font-style: normal;
      font-weight: normal;
      font-size: $font-size-15;
      line-height: $line-height-rate-nano;
      /* identical to box height, or 18px */

      letter-spacing: 0.1px;

      /* System / Blue */

      color: #007aff;

      &:hover {
        cursor: pointer;
      }
    }

    .txt-locked {
      margin-bottom: 30px;
    }

    .btn-login,
    .btn-ok,
    .btn-rs-link {
      border-radius: 0.5 * $space-size-base;
      height: 50px;

      font-style: normal;

      font-size: $font-size-15;
      line-height: $line-height-rate-nano;

      /* System / White */

      color: #ffffff;
    }

    /* Lock 60 Mins */

    .lock-icon {
      text-align: center;
      margin-top: 40px;
      margin-bottom: 50px;
    }

    .txt-title {
      font-style: normal;
      white-space: pre-wrap;
      font-size: $font-size-21;
      line-height: $line-height-rate-micro;
      /* or 28px */
      font-weight: 600;
      letter-spacing: 1px;

      /* Primary Text */

      color: #343434;
    }

    .txt-title-maintenance {
      font-size: $font-size-15 !important;
      font-weight: 500;
      letter-spacing: 0;
    }

    .description {
      margin: 24px 0 34px;
      .txt-description,
      .ant-btn-link {
        font-style: normal;
        font-weight: normal;
        font-size: $font-size-17;
        line-height: $line-height-rate-base;
        /* or 25px */

        letter-spacing: 0.1px;

        /* Text / Primary */

        color: #343434;
      }

      .ant-btn-link {
        padding: 0;
        margin-left: 5px;
        color: #007aff;
      }
    }

    /* Two Factor Authenticate */
    .two-fac-icon {
      text-align: center;
      margin-bottom: $space-size-xl;
    }

    .block-ip-icon {
      text-align: center;
      margin-bottom: 32px;
      margin-top: 30px;
    }

    .user-inactive-icon {
      text-align: center;
      margin-bottom: 32px;
      margin-top: 60px;
    }

    .maintenance-icon {
      text-align: center;
    }

    .space-code-form {
      width: 100%;
      .ant-space-item {
        &:nth-child(1) {
          width: 90%;

          .ant-form-item-has-success {
            .ant-input-affix-wrapper {
              border-color: #40a9ff;
            }
          }

          .input-code-tfa {
            height: 40px;
            font-style: normal;
            font-weight: normal;
            font-size: $font-size-17;
            border-bottom-left-radius: 0.5 * $space-size-base;
            border-top-left-radius: 0.5 * $space-size-base;
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;

            .ant-input-clear-icon {
              display: block;
            }
          }
        }
        &:nth-child(2) {
          .btn-verify {
            border-radius: 0px 0.5 * $space-size-base 0.5 * $space-size-base 0px;
            font-style: normal;

            font-size: $font-size-15;
            height: 40px;
            margin-top: 24px;
          }
        }
      }
    }

    /* De-active account */

    .deactive {
      margin-top: 20px;

      .deactive-info {
        margin: 50px 0;
      }
    }

    /* Popup Ok */
    .popup-ok {
      .popup-icon {
        text-align: center;
        // margin-bottom: $space-size-large;
      }

      .popup-info {
        margin: 32px 0;
        text-align: center;
      }

      .popup-description {
        margin-bottom: 24px;
        display: flex;
        justify-content: center;
        .txt-description {
          font-style: normal;
          font-weight: normal;
          font-size: $font-size-17;
          line-height: $line-height-rate-base;
          /* identical to box height, or 25px */

          display: flex;
          align-items: center;
          letter-spacing: 0.1px;

          color: #343434;
        }
      }
    }

    /* Forgot Password */
    .forgot-pw {
      .txt-title-main {
        text-align: center;
        .txt-title {
        }
      }
      .form-new-pw {
        margin-top: 30px;
      }
      .btn-rs-link {
        margin-top: 6px;
      }
    }
  }
}
