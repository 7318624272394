@import 'styles/variables.scss';

.detail-incidents {
  .close-select {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-picker {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border: 1px solid #dadada;
    box-sizing: border-box;
    border-radius: 0.5 * $space-size-base;
  }
  .ant-form-item-control {
    width: 100%;
  }
  .ant-select-selector {
    border-radius: 0.5 * $space-size-base !important;
  }
  .ant-form-item-label {
    font-size: $font-size-15;
    line-height: $line-height-rate-micro;
    letter-spacing: 0.1px;
    color: #343434;
    padding-bottom: 2px;
    display: inline-flex;
  }

  .ant-form-item-required {
    flex-direction: row-reverse;

    &::before {
      margin-left: 0.5 * $space-size-base;
      content: '( s )' !important;
      font-style: normal;

      font-size: $font-size-15 !important;
      color: #343434 !important;
      font-family: unset !important;
    }
  }

  .hide-content-multi {
    .ant-form-item-required {
      &::before {
        content: '' !important;
      }
    }
  }

  .ant-select-selector {
    min-height: 40px;
  }
}

.menu_targets {
  .menu_dropdown_targets {
    display: flex;
    flex-direction: column-reverse;
    .addnew-container {
      display: flex;
      flex-wrap: nowrap;
      min-height: 46px;
      padding: 5px 1.5 * $space-size-base;
      .anticon-plus {
        color: #007aff;
        margin-right: 0.5 * $space-size-base;
      }
    }
  }
}

.select-dropdown {
  padding: 10px 0;
  .dropdown-addnew {
    background: rgba(64, 158, 255, 0.1);
    min-height: 46px;
    padding: 5px 1.5 * $space-size-base;
    width: 100%;
    cursor: pointer;
    .plus-icon {
      color: #007aff;
      font-size: $font-size-13;
    }
    .plus-text {
      font-size: $font-size-15;
      line-height: $line-height-rate-base;
      letter-spacing: 0.1px;
      color: #007aff;
    }
  }
}
.multile-item {
  min-height: 46px;
  .ant-select-item-option-content {
    padding-left: 0.5 * $space-size-base;
    display: flex;
    align-items: center;
  }
  .ant-select-selector {
    border-radius: 0.5 * $space-size-base;
  }
}

.date-picker {
  .ant-picker-panel-container {
    .ant-picker-date-panel {
      width: 374px;
      height: 268px;
    }

    .ant-picker-header-super-prev-btn {
      display: none !important;
    }
    .ant-picker-header-super-next-btn {
      display: none !important;
    }

    .ant-picker-header-view {
      flex: none;
    }
    .ant-picker-header {
      border-bottom: none;
      padding-left: 55px !important;
      padding-right: 42px !important;
      z-index: 1000;
      width: 50%;
    }

    .ant-picker-today-btn {
      font-size: $font-size-16;
      color: #21c0f6;

      line-height: $line-height-rate-base;
    }
    .ant-picker-footer {
      border-top: none !important;
      position: absolute;
      top: $space-size-base;
      text-align: right;
      right: 50px;
    }
    .ant-picker-body {
      border-top: none;
      width: 100%;
      height: 100%;
      padding: 0;
      padding-left: 39px !important;
      padding-bottom: 32px !important;
      padding-right: 39px !important;
    }

    .ant-picker-content {
      width: 100% !important;
      height: 100%;
    }
    .ant-picker-header-view {
      font-size: $font-size-14;
      line-height: $line-height-rate-nano;
      letter-spacing: 0.4px;
      padding-top: 5px;
    }
    .ant-picker-header-next-btn,
    .ant-picker-header-prev-btn {
      color: #21c0f6 !important;
      font-size: $font-size-15 !important;
    }

    .ant-picker-cell-selected {
      border-radius: 50%;
    }
    .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
    .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
    .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
      border-radius: 50%;
    }

    .ant-picker-cell .ant-picker-cell-inner {
      line-height: $line-height-rate-nano;
      padding-top: 3px;
      font-size: $font-size-13;
      letter-spacing: 0.4px;
      margin-bottom: 2 * $space-size-base;
      margin-left: 2 * $space-size-base;
      margin-right: 2 * $space-size-base;
      margin-top: 3px;
      color: unset;
    }

    .ant-picker-cell-today {
      font-size: $font-size-14;
      line-height: $line-height-rate-base;
      color: #007aff;
    }
    .ant-picker-cell {
      padding: 0;
    }
    .ant-picker-dropdown ant-picker-dropdown-placement-bottomLeft {
      top: 10px;
    }

    .ant-picker-content {
      thead {
        display: none;
      }
    }

    .ant-picker-input > input {
      font-style: normal;
      font-weight: normal;
      font-size: $font-size-15;
      line-height: $line-height-rate-base;
      /* identical to box height, or 25px */

      letter-spacing: 0.1px;
    }
    .ant-picker-suffix,
    .ant-picker-clear {
      font-size: $font-size-20;
    }

    .ant-picker-clear {
      right: 32px;
    }
  }
}

.plus-text {
  font-size: $font-size-17;
  line-height: $line-height-rate-base;
  letter-spacing: 0.1px;
  color: #007aff;
}
.pre-text {
  font-style: normal;
  font-weight: normal;
  font-size: $font-size-15;
  font-family: $font-family;
  line-height: $line-height-rate-base;
  letter-spacing: 0.1px;
  white-space: pre;
}
.ant-select-multiple .ant-select-selection-item-content {
  white-space: break-spaces !important;
}
.ant-select-item-option-content {
  white-space: pre;
}

.box-select-detail {
}
.ant-select-item-option-content {
  pre {
    margin-bottom: 0;
  }
}


