@import "styles/variables.scss";
.automated-response {
  .automated-response-tabs {
    .ant-tabs-nav-wrap {
      border-bottom: 1px solid rgba(0, 0, 0, 0.06);
      padding: 0 2*$space-size-base;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #18c9f3 !important;
    }
    .ant-tabs-ink-bar {
      background: #18c9f3;
    }
    .ant-tabs-tab-btn {
      font-style: normal;
      font-weight: normal;
      font-size: $font-size-17;
      line-height: $line-height-rate-base;
      /* identical to box height, or 25px */

      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: 0.1px;

      &:hover {
        color: #18c9f3;
      }
    }
  }

  .ant-switch {
    background-color: #ff3b30;
  }

  .ant-switch-checked {
    background-color: #34c759;
  }

  .ant-form-item-label {
    font-style: normal;

    font-size: $font-size-15;
    line-height: $line-height-rate-nano;
    /* identical to box height, or 18px */

    color: #000000;
  }

  .save-all {
    display: flex;
    justify-content: center;
    margin-top: 50px;
  }

  /// hours-of-operation
  .hours-of-operation {
    padding: 2*$space-size-base;
    padding-bottom: 200px;
    max-width: 900px;

    .form-auto {
      .ant-form-item-control-input {
        margin-top: 5px;
      }
      .ant-form-item-control-input-content {
        display: flex;
        flex-direction: row;
        align-items: center;

        .wrap-txt {
          margin: -20px 1.5*$space-size-base 0 14px;
          .ant-typography {
            font-style: normal;
            font-size: $font-size-17;
            line-height: $line-height-rate-micro;
            /* or 28px */

            letter-spacing: 0.2px;

            /* Text / Primary */

            color: #343434;
          }
        }

        .ic-info {
          font-size: $font-size-20;
          color: rgba(0, 0, 0, 0.54);
          margin-top: -20px;
        }
      }
    }
    .auto-info {
      // margin-left: 6*$space-size-base;
      .ant-typography {
        font-style: normal;
        font-weight: normal;
        font-size: $font-size-15;
        line-height: $line-height-rate-base;
        /* or 25px */

        letter-spacing: 0.1px;

        /* Text / Primary */

        color: #343434;
      }
      margin-bottom: 32px;
    }

    .wrap-type {
      height: 254px;
      background: rgba(64, 158, 255, 0.1);
      border-radius: 0.5*$space-size-base;
      padding: 2*$space-size-base;
      margin-bottom: 3*$space-size-base;

      .wrap-txt-setting {
        padding: $space-size-base;
        margin-bottom: 3*$space-size-base;
        .ant-typography {
          font-style: normal;

          font-size: $font-size-15;
          line-height: $line-height-rate-base;
          /* identical to box height, or 25px */

          letter-spacing: 0.1px;

          /* Text / Primary */

          color: #343434;
        }
      }
      .radio-type {
        .ant-radio-wrapper {
          padding: 7px;
          min-width: 660px;

          span {
            &:nth-child(2) {
              font-style: normal;
              font-weight: normal;
              font-size: $font-size-15;
              line-height: $line-height-rate-base;

              letter-spacing: 0.1px;

              /* Primary Text */

              color: #343434;
            }
          }
        }
      }

      .ant-radio-wrapper-checked {
        border-radius: 0.5*$space-size-base;
        .ant-radio-checked .ant-radio-inner {
          border-color: #007aff;
          &:after {
            background-color: #007aff;
          }
        }
      }

      .btn-save-permissions {
        font-style: normal;

        font-size: $font-size-15;
        line-height: $line-height-rate-nano;
        /* identical to box height, or 18px */

        /* System / White */

        color: #ffffff;
      }
    }

    .hoo-messages {
      font-style: normal;

      font-size: $font-size-17;
      line-height: $line-height-rate-micro;
      /* or 28px */

      letter-spacing: 0.2px;

      /* Text / Primary */

      color: #343434;
      margin-bottom: 3*$space-size-base;
    }

    .cb-quick-setting {
      border: 1px solid #18bcf3;
      box-sizing: border-box;
      border-radius: 0.5*$space-size-base;
      padding: 10px 2*$space-size-base;

      .ant-checkbox {
        .ant-checkbox-inner {
          display: none;
        }
      }

      .ant-checkbox-checked::after {
        border: none;
      }

      .ant-checkbox-checked {
        .ant-checkbox-inner {
          display: block;
        }
      }

      span {
        font-style: normal;

        font-size: $font-size-15;
        line-height: $line-height-rate-nano;
        /* identical to box height, or 18px */

        /* Brand / Blue 1 */

        color: #18bcf3;
        border-radius: $space-size-base;
      }
    }

    .space-custom {
      width: 100%;
      margin-top: 2*$space-size-base;
      .ant-space-item {
        width: 14.25%;
        .space-custom-item {
          width: 100%;
          .ant-space-item {
            width: 100%;

            .ant-form-item-label {
              margin-bottom: 2*$space-size-base;
              label {
                font-size: $font-size-11;
                font-style: normal;
                font-weight: normal;
                line-height: $line-height-rate-nano;
                /* or 13px */

                letter-spacing: 0.1px;
                text-transform: uppercase;

                /* Text / Primary */

                color: #343434;
              }
            }

            .ant-select-selector {
              border-radius: 0.5*$space-size-base;
              @media screen and (max-width: 1600px) {
                .ant-select-selection-item {
                  max-width: 150px;
                }
              }
              @media screen and (max-width: 1500px) {
                .ant-select-selection-item {
                  max-width: 140px;
                }
              }
              @media screen and (max-width: 1400px) {
                .ant-select-selection-item {
                  max-width: 120px;
                }
              }
              @media screen and (max-width: 1300px) {
                .ant-select-selection-item {
                  max-width: 90px;
                }
              }
              @media screen and (max-width: 1200px) {
                .ant-select-selection-item {
                  max-width: 80px;
                }
              }
              @media screen and (max-width: 1100px) {
                .ant-select-selection-item {
                  max-width: 60px;
                }
              }
              @media screen and (max-width: 1000px) {
                .ant-select-selection-item {
                  max-width: 50px;
                }
              }
              @media screen and (max-width: 900px) {
                .ant-select-selection-item {
                  max-width: 40px;
                }
              }
              @media screen and (max-width: 768px) {
                .ant-select-selection-item {
                  max-width: 30px;
                }
              }
            }
            .ant-select-suffix {
              color: rgba(0, 0, 0, 0.54);
            }

            .from {
              margin-bottom: 20px;
            }

            .to {
              .ant-form-item-label {
                padding-bottom: 0;
              }
            }
          }
        }
      }
    }

    .row-messages {
      .ant-form-item {
        margin-bottom: 0;

        .ant-form-item-label {
          padding-bottom: 2px;
        }
      }

      .btn-edit {
        margin-top: $space-size-base;
        font-style: normal;

        font-size: $font-size-15;
        line-height: $line-height-rate-nano;
        /* identical to box height, or 18px */

        /* BG / White */

        color: #ffffff;
      }
    }
  }

  // holiday
  .holiday {
    padding: 0 2*$space-size-base;

    .table-holiday {
      .ant-table-thead > tr > th {
        &::before {
          display: none;
        }
        font-style: normal;
        font-weight: normal;
        font-size: $font-size-14;
        line-height: $line-height-rate-nano;
        /* or 13px */

        letter-spacing: 0.1px;
        text-transform: uppercase;

        /* Text / Primary */

        color: #343434;
        background: #fff;

        .btn-add-holiday {
          width: 127px;

          span:nth-child(2) {
            font-style: normal;

            font-size: $font-size-15;
            line-height: $line-height-rate-nano;
            /* identical to box height, or 18px */

            /* System / White */

            color: #ffffff;
          }
        }
      }

      .ant-table-tbody > tr > td {
        font-style: normal;
        font-weight: normal;
        font-size: $font-size-15;
        line-height: $line-height-rate-base;
        /* identical to box height, or 25px */
        padding: 1.5*$space-size-base;

        letter-spacing: 0.1px;

        /* Text / Primary */

        color: #343434;

        .ic-delete,
        .ic-edit {
          cursor: pointer;
        }
      }
    }
  }
}

// modal form holiday
.md-form-holiday {
  width: 831px !important;

  .input-name {
    font-style: normal;
    font-weight: normal;
    font-size: $font-size-15;
    color: #343434;
  }

  .ant-picker {
    width: 100%;
    border-radius: 0.5*$space-size-base;

    .ant-picker-suffix,
    .ant-picker-clear {
      font-size: $font-size-20;
    }

    .ant-picker-clear {
      right: 32px;
    }

    .ant-picker-input {
      input {
        font-style: normal;
        font-weight: normal;
        font-size: $font-size-15;
        /* or 20px */

        letter-spacing: 0.5px;

        /* Text / Primary */

        color: #343434;
      }
    }
  }

  .radio-repeat {
    .ant-radio-wrapper {
      span {
        &:nth-child(2) {
          font-style: normal;
          font-weight: normal;
          font-size: $font-size-15;
          line-height: $line-height-rate-base;

          letter-spacing: 0.1px;

          /* Primary Text */

          color: #343434;
        }
      }
    }
  }

  .ant-radio-wrapper-checked {
    .ant-radio-checked .ant-radio-inner {
      border-color: #007aff;
      &:after {
        background-color: #007aff;
      }
    }
  }
}

.select-type-working {
  width: 326px !important;
}
