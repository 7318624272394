@import "styles/variables.scss";
.modal-preview {
  .ant-modal-content {
    border-radius: $space-size-base;
    height: 85vh;
    display: flex;
    flex-direction: column;
  }

  .ant-modal-header {
    border-radius: $space-size-base;
  }
  .ant-modal-body {
    border: none;
    overflow: auto;
    max-height: 100%;
    justify-content: unset;
    text-align: unset;
    .preview {
      overflow-y: scroll;
      max-height: 100%;
    }
  }
}
