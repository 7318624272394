@import 'styles/variables.scss';
.list-deactivated-user {
  padding: 2 * $space-size-base;
  .checkable-action-container {
    margin: $space-size-large 0;

    .selected-txt-block {
      .txt-selected,
      .number-selected {
        font-style: normal;
        font-weight: normal;
        font-size: $font-size-17;
        line-height: $line-height-rate-base;
        /* identical to box height, or 25px */

        letter-spacing: 0.1px;

        /* Text / Primary */

        color: #343434;
      }

      .number-selected {
        color: #007aff;
      }
    }

    .btn-reactivate {
      font-style: normal;

      font-size: $font-size-15;
      line-height: $line-height-rate-nano;
      color: #18bcf3;
      border: 1px solid #18bcf3;
    }
  }

  .table-deactivated-user {
    .ant-table-thead > tr > th {
      &::before {
        display: none;
      }
      font-style: normal;
      font-weight: normal;
      font-size: $font-size-14;
      line-height: $line-height-rate-nano;
      /* or 13px */

      letter-spacing: 0.1px;
      text-transform: uppercase;

      /* Text / Primary */

      color: #343434;
      background: #fff;

      .ant-checkbox {
        display: none;
      }
    }

    .ant-table-tbody > tr > td {
      font-style: normal;
      font-weight: normal;
      font-size: $font-size-15;
      line-height: $line-height-rate-base;
      /* identical to box height, or 25px */
      padding: 1.5 * $space-size-base;

      letter-spacing: 0.1px;

      /* Text / Primary */

      color: #343434;

      .app-id,
      .txt-reactivate {
        color: #007aff;
      }
    }

    .ant-table-tbody > tr.ant-table-row-selected > td {
      background: rgba(64, 158, 255, 0.1);
    }

    .ant-checkbox-checked::after {
      border: 1px solid #007aff;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #007aff;
      border-color: #007aff;
    }
  }
}

.md-reactivate {
  width: 625px !important;

  .space-txt-reactivate {
    margin-bottom: 50px;

    .txt-reactivate {
      font-style: normal;
      font-weight: normal;
      font-size: $font-size-17;
      line-height: $line-height-rate-base;
      /* identical to box height, or 25px */

      letter-spacing: 0.1px;

      /* Text / Primary */

      color: #343434;
    }
  }
}

.md-other-incident-user {
  .ant-modal-body {
    overflow-y: auto;
    max-height: 600px;
  }
  .table-other-incident-user {
    .ant-table-thead > tr > th {
      &::before {
        display: none;
      }
      font-style: normal;
      font-weight: 600;
      font-size: $font-size-14;
      line-height: $line-height-rate-nano;
      padding: $space-size-base;
      /* or 13px */

      letter-spacing: 0.1px;
      text-transform: uppercase;

      padding-left: $space-size-base;
      padding-right: $space-size-base;

      /* Text / Primary */

      color: #343434;
      background: #fff;
    }

    .ant-table-tbody > tr {
      height: 40px;
    }

    .ant-table-tbody > tr > td.incident-sequence {
      padding-right: 24px;
      text-align: right;
    }

    .ant-table-tbody > tr > td {
      font-style: normal;
      font-weight: normal;
      font-size: $font-size-15;
      line-height: $line-height-rate-base;
      /* identical to box height, or 25px */
      padding: 0 1.5 * $space-size-base;

      letter-spacing: 0.1px;

      /* Text / Primary */

      color: #343434;

      .txt-incident {
        color: #007aff;
      }
    }

    td.ant-table-column-sort {
      background: unset;
    }

    .ant-table-row {
      &:hover {
        td {
          // font-weight: 500 !important;
          cursor: pointer;
        }
      }
    }

    .row-odd {
      background: rgba(144, 147, 153, 0.1);
    }
  }
}
