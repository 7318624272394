@import 'styles/variables.scss';

.standby-notice-success,
.standby-notice-error {
  .ant-message-notice-content {
    min-width: 650px;
    border-radius: 0.5*$space-size-base;

    .ant-message-custom-content {
      display: flex;
      .content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .msg {
          width: 600px;
          text-align: left;
          margin-left: 10px;
          font-style: normal;
          font-weight: normal;
          font-size: $font-size-14;
          line-height: $line-height-rate-base;
          /* identical to box height, or 25px */

          letter-spacing: 0.1px;

          /* Text / Primary */

          color: #343434;
        }
        .close-icon {
          cursor: pointer;
        }
      }
      
      .anticon-check-circle,
      .anticon-close-circle {
        display: none;
        align-items: center;
      }
    }
  }
}

.standby-notice-success {
  .ant-message-notice-content {
    border: 1px solid #34c759;
    background-color: #eaffe2;
  }
}

.standby-notice-error {
  .ant-message-notice-content {
    border: 1px solid #ff3b30;
    background-color: #ffe7e7;
  }
}
