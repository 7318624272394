@import 'styles/variables.scss';
@import './components/freeze-incident/freeze-incident.scss';
@import './components/deactived-user/deactivated-user.scss';
@import './components/incident-question/incident-question.scss';
@import './components/organization/organization-setup.scss';
@import './components/admin-user/admin-user-setup.scss';
@import './components/incident-management/incident-management.scss';
@import './components/incident-message/incident-message.scss';
@import './components/automated-response/automated-response.scss';
@import './components/security/security.scss';
@import './components/sos-setup/sos-setup.scss';

.setting {
  height: 100%;

  .content-setting-page {
    width: calc(100% - 260px);
    .incident-question .table-question {
      margin-top: 0;
    }
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    width: 0;
  }

  .menu-layout {
    height: 100%;
    .ant-layout-sider-children {
      .logo {
        display: none;
      }
    }

    .layout-menu-left {
      min-width: 260px !important;
      position: absolute;
      left: 0;
      z-index: 1;
      height: calc(100% - 108px);
    }

    .menu-setting {
      height: 100%;
      /*      position: fixed;*/
      padding: $space-size-base 2 * $space-size-base;

      li.ant-menu-item-only-child {
        padding-left: 2.25 * $space-size-base !important;
        padding-right: $space-size-base !important;
      }

      .ant-menu-item-group-title {
        font-style: normal;
        font-weight: bold;
        font-size: $font-size-15;
        line-height: $line-height-rate-nano;
        /* identical to box height, or 16px */
        letter-spacing: 0.1px;
        /* Text / Primary */
        color: #343434;
        padding-left: 0;
      }

      .ant-menu-item-selected {
        border-radius: $space-size-tiny;
        color: #18c9f3;
        background: #ffffff;
        border: 0.5 * $space-size-tiny solid #5ac8fa;
      }
      .ant-menu-title-content {
        font-style: normal;
        font-weight: normal;
        font-size: $font-size-15;
        line-height: $line-height-rate-nano;
        /* identical to box height, or 18px */

        letter-spacing: 0.1px;

        /* Brand / Blue 2 */
      }

      .ant-menu-item::after {
        border-right: 0;
      }
    }

    .site-layout {
      .header-setting {
        background: #fff;
        height: 60px;
        display: flex;
        align-items: center;

        background: #f9f9f9;
        /* Inner Shadow / Cell Table */

        box-shadow: inset 0px -1px 0px rgba(144, 147, 153, 0.2);

        /*        position: fixed;*/
        z-index: 99;
        width: 100%;

        .header-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          padding-right: 16px;

          .txt-setup,
          .txt-current-page {
            font-style: normal;
            font-weight: normal;
            font-size: $font-size-17;
            line-height: $line-height-rate-base;
            /* identical to box height, or 25px */

            letter-spacing: 0.1px;

            /* System / Blue */

            color: #007aff;
          }

          .txt-current-page {
            color: #343434;
          }

          .show-hidden-og {
            .switch-hidden {
              background-color: #ff3b30;
              height: 12px;
              line-height: 12px;
              margin-bottom: 3px;

              .ant-switch-handle {
                width: $space-size-base;
                height: $space-size-base;
              }
            }

            .switch-hidden.ant-switch-checked {
              background-color: #34c759;
            }
            .txt-show-hidden {
              font-style: normal;

              font-size: $font-size-15;
              line-height: $line-height-rate-nano;
              color: #343434;
            }
          }
        }

        .trigger {
          font-size: $font-size-20;
          z-index: 1;
          margin-left: -10px;
          color: #18bcf3;
        }
      }
      .ant-layout-content {
        background: #fff;
        padding-bottom: 0;
      }
    }
  }
}
