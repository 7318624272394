@import 'styles/variables.scss';
.detail-incidents {
  .activity {
    display: flex;
    width: 100%;
    flex-direction: column;
    background: #ffffff;
    height: calc(100vh - 220px);
    .activity-list {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      position: relative;
      .space {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        padding: $space-size-base 2 * $space-size-base;
        overflow: auto;
        .ant-space-item {
          width: 100%;
        }
        .activity-item {
          background-color: #f9f9f9;

          box-shadow: 0px 0px 4px #c6c6c8;
          border-radius: 0.5 * $space-size-base;
          padding: 2 * $space-size-base;
          width: 100%;
          height: 100%;
          .header-item {
            display: flex;
            align-items: center;
            position: relative;
            .title {
              font-size: $font-size-15;
              line-height: $line-height-rate-base;
              letter-spacing: 0.1px;
              color: #343434;
              margin-bottom: 1.5 * $space-size-base;
              margin-right: 20px;
            }
            // .text::before {
            //   content: '.';
            //   position: absolute;
            //   font-size: 2rem;
            //   top: -0.5*$space-size-base;
            //   margin-left: -1.5*$space-size-base;
            //   color: #c4c4c4;
            // }
            .text {
              padding-bottom: 10px;
              font-size: $font-size-13;
              line-height: $line-height-rate-nano;
              display: flex;
              align-items: center;
              letter-spacing: 0.1px;
              color: #818181;
              margin-right: 20px;
            }
          }

          .activity-text {
            font-size: $font-size-15;
            line-height: $line-height-rate-base;
            letter-spacing: 0.1px;
            color: #343434;
          }
        }
      }
    }
  }
}
