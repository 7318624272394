@import 'styles/variables.scss';

.security {
  padding: 0 2 * $space-size-base;
  & {
    .type {
      display: flex;
      @media screen and (max-width: 1366px) {
        padding: 10px 0 !important;
      }

      & {
        .ant-switch {
          margin-right: 28px;
          margin-top: 5px;
        }
        .ant-space {
          display: flex;
          flex-direction: column;
          & {
            .title {
              font-size: $font-size-17;
              line-height: $line-height-rate-micro;
              letter-spacing: 0.2px;
              color: #343434;
            }
            .text {
              font-size: $font-size-15;
              line-height: $line-height-rate-base;
              display: flex;
              align-items: center;
              letter-spacing: initial;
              color: #343434;
            }
          }
        }
      }
    }
  }

  .list-security {
    .header-list {
      height: 48px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-shadow: inset 0px -1px 0px rgba(144, 147, 153, 0.2);
      margin-left: 2 * $space-size-base;
      margin-top: 3 * $space-size-base;
      margin-bottom: 14px;
      .title {
        font-weight: normal;
        font-size: $font-size-11;
        color: #343434;
        padding-left: 1.5 * $space-size-base;
      }
      .add {
        .btn-add {
          span:nth-child(2) {
            font-style: normal;

            font-size: $font-size-15;
            line-height: $line-height-rate-nano;
            /* identical to box height, or 18px */

            /* System / White */

            color: #ffffff;
          }
        }
      }
    }
    .body {
      overflow: auto;
      padding-bottom: 60px;
      .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 48px;
        box-shadow: inset 0px -1px 0px rgba(144, 147, 153, 0.2);
        .left {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .name {
            font-style: normal;
            font-weight: normal;
            font-size: $font-size-17;
            color: #343434;
          }
        }
        .right {
          margin-right: 20px;
          .ic-edit,
          .ic-delete {
            cursor: pointer;
          }
        }
      }
    }
  }
}

.modal-lastone {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;

  .ant-modal-content {
    width: 800px;
    height: 271px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .ant-modal-body {
    display: none;
  }
  .ant-modal-header {
    border-bottom: none;
  }
  .ant-modal-title {
    font-style: normal;

    font-size: $font-size-21;
    line-height: $line-height-rate-micro;

    letter-spacing: 0.2px;

    color: #343434;
  }
  .btn-lastone {
    height: 40px;
    background: #ffffff;
    border: 1px solid #e0e0e0;

    box-sizing: border-box;
    border-radius: 0.5 * $space-size-base;
  }
}
