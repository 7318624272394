@import "styles/variables.scss";
.reports {
  .bulk-incidents {
    padding: 4 * $space-size-base 2 * $space-size-base;

    .ant-form-item-label {
      font-style: normal;

      font-size: $font-size-15;
      line-height: $line-height-rate-nano;
      /* identical to box height, or 18px */

      letter-spacing: 0.1px;

      /* Text / Primary */

      color: #343434;
      padding-bottom: 2px !important;
      display: inline-flex;
    }

    .range-picker {
      border-radius: 0.5 * $space-size-base;
      width: 100%;

      .ant-picker-input {
        input {
          font-style: normal;
          font-weight: normal;
          font-size: $font-size-15;
          letter-spacing: 0.5px;
        }
      }

      .ant-picker-suffix,
      .ant-picker-clear {
        font-size: $font-size-20;
      }

      .ant-picker-clear {
        margin-right: 28px;
      }
    }

    .select-organization,
    .select-status {
      .ant-select-selector {
        border-radius: 0.5 * $space-size-base;
        .ant-select-selection-item {
          font-style: normal;
          font-weight: normal;
          font-size: $font-size-15;
        }
      }

      .ant-select-clear {
        font-size: $font-size-20;
        margin-right: 32px;
        margin-top: -10px;
      }
    }

    .btn-export {
      font-style: normal;

      font-size: $font-size-15;
      line-height: $line-height-rate-nano;
      height: 32px;
    }
  }
}

// .range-bulk {
//   .ant-picker-range-wrapper {
//     display: block;
//     .ant-picker-panel-container {
//       flex-direction: unset;
//       .ant-picker-panels {
//         display: none;
//       }
//     }
//   }
// }
