@import 'styles/variables.scss';
.incident-message {
  padding: 0 2 * $space-size-base;
  .ant-form {
    padding: 32px 0px;

    .wrap-select_box {
      .container {
        .select-item {
          flex: initial;
          min-width: calc(50% - 8px);
        }
      }
      display: flex;
      flex-direction: column;

      .container .select-item-msg {
        max-width: initial;
        flex: initial;
        width: 250px;

        @media not all and (min-resolution: 0.001dpcm) {
          @supports (-webkit-appearance: none) {
            max-width: 48%;
          }
        }
      }
      .input-item {
        width: 250px;
        margin-left: 0;
        @media not all and (min-resolution: 0.001dpcm) {
          @supports (-webkit-appearance: none) {
            max-width: 48%;
          }
        }
        .ant-input-number {
          max-width: initial !important;
        }
      }
      .box-select-bottom {
        display: flex;
        flex-direction: column;
        min-width: 450px;
      }
    }
    & {
      .item-btn {
        .ant-form-item-control-input-content {
          display: flex;
        }
        .ant-btn {
          max-height: 67px;

          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 42px;
          padding: 10px 2 * $space-size-base;

          background: #18bcf3;
          border-radius: 0.5 * $space-size-base;
          font-size: $font-size-15;

          border: 1px solid #dadada;
          line-height: $line-height-rate-nano;
          letter-spacing: 0.1px;
          color: #ffffff;
        }
      }
      .container {
        width: 100%;
        margin-bottom: 34px;
        display: flex;
        // flex-wrap: wrap;
        @media screen and(max-width:1500px) {
          margin-bottom: 10px;
        }
        .select-item-msg {
          display: flex;
          flex-direction: column;
          flex: 1;
          max-width: 380px;
          margin-right: 2 * $space-size-base;
          @media screen and(max-width:1500px) {
            margin-top: 10px;
          }
          & {
            .ant-btn {
              display: flex;
              align-items: center;
              justify-content: space-between;
              height: 42px;
              margin: 0.5 * $space-size-base 0px;
              width: 100%;
              border: 1px solid #dadada;
              box-sizing: border-box;
              border-radius: 0.5 * $space-size-base;

              font-size: $font-size-15;
              line-height: $line-height-rate-base;
              letter-spacing: 0.1px;
              color: #343434;
              svg {
                font-size: $font-size-12;
              }
            }
          }
        }
        .select-item {
          display: flex;
          flex-direction: column;
          flex: 1;
          max-width: 380px;
          @media screen and(max-width:1500px) {
            margin-top: 10px;
          }
          & {
            .ant-btn {
              display: flex;
              align-items: center;
              justify-content: space-between;
              height: 42px;
              margin: 0.5 * $space-size-base 0px;
              width: 100%;
              border: 1px solid #dadada;
              box-sizing: border-box;
              border-radius: 0.5 * $space-size-base;

              font-size: $font-size-15;
              line-height: $line-height-rate-base;
              letter-spacing: 0.1px;
              color: #343434;
              svg {
                font-size: $font-size-12;
              }
            }
          }
        }
        .input-item {
          display: flex;
          flex-direction: column;
          @media screen and(max-width:1500px) {
            margin-top: 10px;
          }
          .ant-input-number {
            max-width: 172px;
            width: 100%;
            height: 42px;
            padding: 5px;
            margin: 0.5 * $space-size-base 0px;
            border: 1px solid #dadada;
            box-sizing: border-box;
            border-radius: 0.5 * $space-size-base;
            font-size: $font-size-15;
          }
        }
      }

      .input-disabled {
        max-width: 515px;
        height: 42px;
        padding: $space-size-base 1.5 * $space-size-base;
        margin: 0.5 * $space-size-base 0px;
        border: 1px solid #dadada;
        box-sizing: border-box;
        border-radius: 0.5 * $space-size-base;
      }

      .ant-radio-group {
        width: 100%;
      }
      .ant-space {
        width: 100%;
      }
      .ant-space-item {
        width: 100%;
        background: #ffffff;
        border: 1px solid #e0e0e0;
        box-sizing: border-box;
        border-radius: 0.5 * $space-size-base;
      }
      .radio-branding,
      .radio-og-type {
        .title-msg {
          font-size: $font-size-15;
          line-height: $line-height-rate-micro;
          letter-spacing: 0.2px;
          color: #343434;
        }
        .txt-msg {
          font-size: $font-size-15;
          line-height: $line-height-rate-base;
          display: flex;
          align-items: center;
          letter-spacing: 0.1px;
          color: #343434;
          margin-bottom: 23px;
          @media screen and(max-width:1500px) {
            margin-bottom: 0;
          }
        }
        .text {
          font-size: $font-size-15;
          line-height: $line-height-rate-nano;
          letter-spacing: 0.1px;
          color: #343434;
          min-width: 15rem;
        }
        .ant-radio-wrapper {
          padding: 26px;
          min-width: 660px;
          span {
            &:nth-child(2) {
              font-style: normal;
              font-weight: normal;
              font-size: $font-size-15;
              line-height: $line-height-rate-base;

              letter-spacing: 0.1px;

              /* Primary Text */

              color: #343434;
            }
          }
        }
        .ant-radio-wrapper-checked {
          width: 100%;
          border-radius: 0.5 * $space-size-base;
          border: 1px solid #007aff;
          background: rgba(64, 158, 255, 0.1);
          .ant-radio-checked .ant-radio-inner {
            border-color: #007aff;
            &:after {
              background-color: #007aff;
            }
          }
        }
      }
    }
  }
}
// .ant-select-dropdown {
//   min-width: 326px !important;
//   padding: 0;
//   max-height: 348px !important;
//   .ant-select-item {
//     padding: 15px 16px;
//   }
//   &::-webkit-scrollbar {
//     width: 8px;
//   }
// }

.menu-limit {
  max-height: 300px;
  width: 100%;
  height: 100%;
  overflow-y: scroll;

  & {
    li {
      padding: $space-size-large;
      font-size: $font-size-15;
      line-height: $line-height-rate-base;
      letter-spacing: 0.1px;
      color: #343434;
    }
  }
}
