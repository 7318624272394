@import 'styles/variables.scss';

.freeze-incident {
  padding: 2*$space-size-base;

  .ant-switch {
    background-color: #ff3b30;
  }

  .ant-switch-checked {
    background-color: #34c759;
  }

  .form-freeze {
    .ant-form-item-control-input {
      margin-top: 5px;
    }
    .ant-form-item-control-input-content {
      display: flex;
      flex-direction: row;
      align-items: center;

      .wrap-txt {
        margin: -20px 0 0 28px;
        .ant-typography {
          font-style: normal;

          font-size: $font-size-21;
          line-height: $line-height-rate-micro;
          /* or 28px */

          letter-spacing: 0.2px;

          /* Text / Primary */

          color: #343434;
        }
      }
    }
  }

  .wrap-form {
    padding: 0 58px;
    .space-form-input {
      width: 100%;

      .ant-space-item {
        min-width: 334px;

        .ant-form-item-label {
          font-style: normal;

          font-size: $font-size-15;
          line-height: $line-height-rate-nano;
          /* identical to box height, or 18px */

          display: flex;
          align-items: center;
          letter-spacing: 0.1px;

          /* Text / Primary */

          color: #343434;
          padding-bottom: 2px;
        }

        .ant-picker {
          width: 100%;
          border-radius: 0.5*$space-size-base;

          .ant-picker-suffix,
          .ant-picker-clear {
            font-size: $font-size-20;
          }

          .ant-picker-clear {
            right: 32px;
          }

          .ant-picker-input {
            input {
              font-style: normal;
              font-weight: normal;
              font-size: $font-size-17;
              /* or 20px */

              letter-spacing: 0.5px;

              /* Text / Primary */

              color: #343434;
            }
          }
        }
      }
    }

    .btn-apply {
      font-style: normal;

      font-size: $font-size-15;
      line-height: $line-height-rate-nano;
      /* identical to box height, or 18px */

      display: flex;
      align-items: center;

      /* System / White */

      color: #ffffff;

      &:disabled {
        border: none;
        background: #c6c6c8;
      }
    }
  }
}
