@import 'styles/variables.scss';

.share-incident {
  margin: 0 auto;
  max-width: 1000px;
  margin-top: 50px;

  .txt-no-data {
    font-style: normal;
    font-weight: normal;
    font-size: $font-size-24;
    line-height: $line-height-rate-base;

    color: #343434;
  }

  .share-incident-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .logo {
      height: 68px;
      width: 320px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }

    .txt-incident {
      font-style: normal;
      font-weight: normal;
      font-size: $font-size-48;
      line-height: $line-height-rate-base;

      color: #343434;
    }
  }

  .page-header {
    margin-top: 100px;
    margin-bottom: 60px;
    width: 100%;
    .ant-space-item {
      width: 100%;
      .txt-head {
        font-style: normal;

        font-size: $font-size-36;
        line-height: $line-height-rate-nano;
        letter-spacing: 0.25px;
        text-transform: uppercase;
        color: #18c9f3;
      }

      .line {
        height: 0.5 * $space-size-base;
        width: 100%;
        background: #18c9f3;
      }
    }
  }

  .share-incident-description {
    .ant-descriptions-title {
      font-style: normal;

      font-size: $font-size-36;
      line-height: $line-height-rate-nano;
      letter-spacing: 0.25px;
      color: #343434;
      margin-bottom: 60px;
    }

    .ant-descriptions-item {
      padding-bottom: 32px;

      .ant-descriptions-item-label {
        width: 340px;
        padding-right: $space-size-large;

        .label-with-sub {
          .label-sub {
            font-style: normal;
            font-weight: normal;
            font-size: $font-size-17;
            line-height: $line-height-rate-base;
          }
        }
      }

      .ant-descriptions-item-label,
      .ant-descriptions-item-content {
        font-style: normal;
        font-weight: normal;
        font-size: $font-size-25;
        line-height: $line-height-rate-nano;
        color: #343434;

        &::after {
          display: none;
        }
      }

      .ant-descriptions-item-container {
        &:last-child {
          font-style: normal;
          font-weight: normal;
          font-size: $font-size-25;
          line-height: $line-height-rate-nano;
          color: #343434;
        }
      }
    }
  }

  .share-incident-description.with-title {
    margin-bottom: 80px;
  }
}
