@import 'styles/variables.scss';

.profile {
  .divider-profile {
    margin: 0;
  }
  .title {
    display: flex;
    align-items: center;
    height: 60px;
    padding-left: 2 * $space-size-base;
    /*position: fixed;*/
    z-index: 1;
    width: 100%;

    background: #f9f9f9;
    /* Inner Shadow / Cell Table */

    box-shadow: inset 0px -1px 0px rgba(144, 147, 153, 0.2);

    .txt {
      font-style: normal;
      font-weight: normal;
      font-size: $font-size-17;
      line-height: $line-height-rate-base;
      /* identical to box height, or 25px */

      letter-spacing: 0.1px;

      /* Text / Primary */

      color: #343434;
    }
  }

  .opt-in-board {
    .ant-table-cell-with-append {
      display: flex;
      align-items: center;
    }
  }

  .block-content {
    padding: 2 * $space-size-base;

    .setting-tab {
      .ant-tabs-nav-wrap {
        border-bottom: 1px solid rgba(0, 0, 0, 0.06);
        height: 52px;
      }
      .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #18c9f3 !important;
      }
      .ant-tabs-ink-bar {
        background: #18c9f3;
      }
      .ant-tabs-tab-btn {
        font-style: normal;

        font-size: $font-size-17;
        line-height: $line-height-rate-base;
        /* identical to box height, or 25px */

        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.1px;

        &:hover {
          color: #18c9f3;
        }
      }

      .my-info {
        margin-top: 30px;
        padding-bottom: 60px;

        .ant-form-item-label {
          font-style: normal;

          font-size: $font-size-15;
          line-height: $line-height-rate-nano;
          letter-spacing: 0.1px;
          padding-bottom: 0.5 * $space-size-base;
        }

        .ant-input-disabled {
          color: #343434;
        }

        .sl-language .ant-select-selector {
          border-radius: 0.5 * $space-size-base;
          height: 34px;
          .ant-select-selection-item {
            line-height: 34px;
          }
        }

        .space-input-phone {
          width: 100%;
          .ant-space-item:nth-child(1) {
            width: 84px;
          }
          .ant-space-item:nth-child(2) {
            width: 100%;
          }
        }

        .btn-change-pw,
        .btn-save {
          font-style: normal;

          font-size: $font-size-15;
          line-height: $line-height-rate-nano;
          /* identical to box height, or 18px */

          display: flex;
          align-items: center;

          /* Brand / Blue 1 */

          color: #18bcf3;
          border: 1px solid #18bcf3;
        }

        .btn-save {
          color: #fff;
        }
      }

      .my-role,
      .my-opt-ins {
        .btn-edit {
          font-style: normal;

          font-size: $font-size-15;
          line-height: $line-height-rate-nano;
          color: #ffffff;
        }
        .board {
          margin-top: 30px;
          margin-bottom: 75px;

          .ant-table-thead > tr > th {
            &::before {
              display: none;
            }
            font-style: normal;
            font-weight: normal;
            font-size: $font-size-14;
            line-height: $line-height-rate-nano;
            /* or 13px */

            letter-spacing: 0.1px;
            text-transform: uppercase;

            /* Text / Primary */

            color: #343434;
            background: #fff;

            .ic-info {
              color: rgba(0, 0, 0, 0.54);
            }
          }

          .ant-table-tbody > tr > td {
            font-style: normal;
            font-weight: normal;
            font-size: $font-size-15;
            line-height: $line-height-rate-base;
            /* identical to box height, or 25px */
            padding: 1.5 * $space-size-base;

            letter-spacing: 0.1px;

            /* Text / Primary */

            color: #343434;

            .ic-expand {
              color: #007aff;
              margin-right: 1.5 * $space-size-base;
              font-size: $font-size-13;
            }

            .fake-expand {
              margin-right: $space-size-large;
              color: transparent;
            }

            .ant-checkbox-checked::after {
              border: 1px solid #007aff;
            }
            .ant-checkbox-checked .ant-checkbox-inner {
              background-color: #007aff;
              border-color: #007aff;
            }

            .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner {
              background-color: #e3e3e3;
            }
            .ant-checkbox-disabled .ant-checkbox-inner {
              background-color: #fff;
              opacity: 0.5;
            }

            .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
              border-color: #fff;
            }

            .access-limiter {
              font-style: normal;
              font-weight: normal;
              font-size: $font-size-15;
              line-height: $line-height-rate-nano;

              color: #343434;
              width: 100%;
            }
          }
        }
      }

      .my-role {
        padding-bottom: 70px;
        .board {
          margin: 0;

          .cb-expand {
            span {
              &:nth-child(2) {
                font-style: normal;
                font-weight: normal;
                font-size: $font-size-14;
                line-height: $line-height-rate-nano;

                color: #343434;
              }
            }
          }
        }
      }
    }
  }
}
