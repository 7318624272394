@import 'styles/variables.scss';

.ant-popover {
  z-index: 1010;
}
.overlay-notifications {
  width: 520px;
  left: calc(100% - 625px) !important;

  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-content {
    background: #ffffff;
    border-radius: $space-size-base;
    width: 520px;

    .ant-popover-inner {
      height: 100%;
      border-radius: $space-size-base;

      .ant-popover-title {
        padding-top: 10px;
        background: #ffffff;
        border-radius: $space-size-base $space-size-base 0 0;

        .title-notifications {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-style: normal;

          font-size: $font-size-17;
          padding-bottom: 2 * $space-size-base;
          line-height: $line-height-rate-micro;
          letter-spacing: 0.2px;
          color: #343434;
          padding-top: 2 * $space-size-base;

          .btn-see-all {
            font-style: normal;

            font-size: $font-size-15;
            line-height: $line-height-rate-micro;
            color: #007aff;
            cursor: pointer;
          }
        }
      }

      .ant-popover-inner-content {
        padding-top: 0px;
        padding-right: 0;
        .ant-list-items {
          max-height: 500px;
        }

        .ant-list-item {
          background: #ffffff;
          box-shadow: inset 0px -1px 0px rgba(144, 147, 153, 0.2);
          cursor: pointer;
          min-height: 72px;
          border-bottom: none;
          padding-right: 16px;

          .ant-list-item-meta {
            .ant-list-item-meta-title {
              width: 100%;
              font-style: normal;

              font-size: $font-size-13;
              line-height: $line-height-rate-nano;
              align-items: center;
              letter-spacing: 0.1px;
              color: #343434;
              .ant-space.ant-space-vertical {
                width: 100%;
              }

              .incident-number {
                margin-top: 6px;
                position: static;
                font-style: normal;
                font-weight: normal;
                font-size: $font-size-15;
                line-height: $line-height-rate-base;
                letter-spacing: 0.1px;
                color: #007aff;
              }

              .message {
                padding-right: 40px;
              }
            }

            .ant-list-item-meta-avatar {
              .avatar {
                background: #f2f2f2;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                  width: unset;
                  height: unset;
                }
              }
            }

            .ant-list-item-meta-description {
              font-style: normal;
              font-weight: normal;
              font-size: $font-size-15;
              line-height: $line-height-rate-nano;
              letter-spacing: 0.1px;
              color: #c6c6c8;
            }
          }

          .is-read {
            width: 12px;
            height: 12px;
            background: #18c9f3;
            border-radius: 1.5 * $space-size-base;
          }
        }
      }
    }
  }
}

.md-detail-notification {
  .table-incident-notice {
    .ant-table-thead > tr > th {
      &::before {
        display: none;
      }
      font-style: normal;
      font-weight: 600;
      font-size: $font-size-14;
      line-height: $line-height-rate-nano;
      padding: $space-size-base;
      /* or 13px */

      letter-spacing: 0.1px;
      text-transform: uppercase;

      /* Text / Primary */

      color: #343434;
      background: #fff;
    }

    .ant-table-tbody > tr {
      height: 40px;
    }

    .ant-table-tbody > tr > td.incident-sequence {
      padding-right: 24px;
      text-align: right;
    }

    .ant-table-tbody > tr > td {
      font-style: normal;
      // font-weight: normal;
      font-size: $font-size-15;
      line-height: $line-height-rate-base;
      /* identical to box height, or 25px */
      padding: 0 1.5 * $space-size-base;

      letter-spacing: 0.1px;

      /* Text / Primary */

      color: #343434;

      .txt-incident {
        color: #007aff;
      }
    }

    td.ant-table-column-sort {
      background: unset;
    }

    .ant-table-row {
      &:hover {
        td {
          // font-weight: 500 !important;
          cursor: pointer;
        }
      }
    }

    .row-odd {
      background: rgba(144, 147, 153, 0.1);
    }

    .row-bold {
      font-weight: 700;
    }
  }
}

.modal-system-alert {
  .message-alert {
    font-style: normal;
    font-weight: normal;
    font-size: $font-size-15;
    line-height: $line-height-rate-base;

    display: flex;
    letter-spacing: 0.1px;
    color: #343434;
    margin: 20px 0px;
  }

  .message-files {
    width: 420px;
    border: 1.5px solid #21c0f6;
    height: 35px;
    border-radius: 4px;
    gap: 10px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    margin-top: 10px;

    .text-message {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
    }
  }

  .title-notification {
    width: 650px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
