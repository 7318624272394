@import 'styles/variables.scss';

.list-incidents {
  .header-incidents {
    height: 60px;
    background-color: #fff;
    box-shadow: inset 0px -1px 0px rgba(144, 147, 153, 0.2);
    padding: 0 $space-size-large;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /*position: fixed;*/
    z-index: 99;
    width: 100%;

    .wrap-info-incident {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60px;
    }

    .wrap-dropdown-org {
      width: 20%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    .txt-incident {
      font-style: normal;
      font-weight: normal;
      font-size: $font-size-17;
      line-height: $line-height-rate-base;
      letter-spacing: 0.1px;
      color: #343434;
    }

    .wrap-badge-incident {
      .new {
        color: #5856d6;
        font-size: $font-size-17;
      }
      .pending {
        color: #ffcc00;
        font-size: $font-size-17;
      }

      .unread-mess {
        color: #ff3b30;
        font-size: $font-size-17;
      }

      .space-badge {
        .badge-incident {
          height: 35px;
          width: 61px;
          cursor: pointer;

          .ant-scroll-number {
            padding-top: 2px;
          }

          .ant-badge-count {
            height: 35px;
            border-radius: 58px;
            font-style: normal;

            font-size: $font-size-21;
            letter-spacing: 0.2px;
            color: #ffffff;
            line-height: $line-height-rate-micro;
          }
        }

        .new {
          .ant-badge-count {
            background: #5856d6;
          }
        }

        .pending {
          .ant-badge-count {
            background: #ffcc00;
          }
        }
        .unread-mess {
          .ant-badge-count {
            background: #ff3b30;
          }
        }
        .badge-txt {
          font-style: normal;

          font-size: $font-size-15;
          /* identical to box height, or 18px */

          letter-spacing: 0.1px;

          /* Text / Primary */

          color: #343434;
          text-transform: uppercase;
        }
      }
    }
  }

  .filters-incidents {
    padding: $space-size-base 2 * $space-size-base;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /*position: fixed;*/
    width: 100%;
    z-index: 99;
    background-color: #fff;

    .search-input {
      width: 320px;
      border-radius: 0.5 * $space-size-base;
      margin-bottom: 0.5 * $space-size-base;
      height: $input-height-30;

      .ant-input-prefix {
        color: rgba(0, 0, 0, 0.54);
      }
    }

    .select-status,
    .select-response-status {
      min-width: 135px;
      margin-top: -0.5 * $space-size-base;
      .ant-select-selector {
        border-radius: 0.5 * $space-size-base;
        .ant-select-selection-item {
          font-style: normal;

          font-size: $font-size-15;
          color: #343434;
        }

        .ant-select-selection-placeholder {
          color: #343434;
          font-style: normal;
          font-size: $font-size-15;
        }
      }
    }

    .select-response-status {
      min-width: 205px;
    }

    .cb-assigned {
      margin-left: $space-size-large;
      .ant-checkbox-checked::after {
        border: 1px solid #007aff;
      }
      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #007aff;
        border-color: #007aff;
      }

      span:nth-child(2) {
        font-style: normal;
        font-weight: normal;
        font-size: $font-size-15;

        color: #343434;
      }
    }

    .btn-add-incident {
      span:nth-child(2) {
        font-style: normal;

        font-size: $font-size-15;
        line-height: $line-height-rate-nano;
        /* identical to box height, or 18px */

        /* System / White */

        color: #ffffff;
      }
    }
  }

  .table-incident {
    padding: 0 2 * $space-size-base;
    // .ant-table-thead > tr > th:first-child {
    //   .ant-table-column-sorters {
    //     text-align: right;
    //     padding-right: 12px;
    //     display: flex;
    //     flex: unset;
    //     align-items: unset;
    //     justify-content: unset;
    //   }
    // }
    .ant-table-thead > tr > th {
      &::before {
        display: none;
      }
      font-style: normal;
      font-weight: 600;
      font-size: $font-size-14;
      line-height: $line-height-rate-nano;
      /* or 13px */

      letter-spacing: 0.1px;
      text-transform: uppercase;

      /* Text / Primary */

      color: #343434;
      background: #fff;
    }

    .ant-table-cell {
      padding: $space-size-base;
    }

    .ant-table-tbody > tr:nth-child(1) {
      height: 0px !important;
    }

    .ant-table-tbody > tr {
      height: 40px;
    }

    .ant-table-tbody > tr > td.incident-sequence {
      text-align: center;
      // padding-left: 50px;
    }

    .ant-table-tbody > tr > td {
      padding: 0 1.5 * $space-size-base;
      font-size: $font-size-15;
      line-height: $line-height-rate-base;
      /* identical to box height, or 25px */
      letter-spacing: 0.1px;
      white-space: pre;
      /* Text / Primary */

      color: #343434;

      .txt-incident {
        color: #007aff;
      }
    }

    td.ant-table-column-sort {
      background: unset;
    }

    .ant-table-row {
      &:hover {
        td {
          // font-weight: 500 !important;
          cursor: pointer;
        }
      }
    }

    .row-odd {
      background: rgba(144, 147, 153, 0.1);
    }

    .row-bold {
      font-weight: 700;
    }
  }
}

.md-create-incident {
  .ant-modal-body {
    padding: 0;
    margin: 3 * $space-size-base;
    position: relative;
  }
  .select-organization {
    .ant-select-selector {
      border-radius: 0.5 * $space-size-base;
      .ant-select-selection-item {
        font-size: $font-size-15;
      }
    }
  }

  .input-report {
    font-style: normal;
    font-weight: normal;
    font-size: $font-size-15;
    line-height: $line-height-rate-base;
    /* identical to box height, or 25px */

    letter-spacing: 0.1px;

    color: #000000;
  }

  .preview-file-upload {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px dashed #007aff;
    background: #ebf5ff;
    z-index: 1000;
  }

  .preview-file-upload-hidden {
    display: none !important;
  }

  .upload-incidents {
    border: 1px solid transparent;
    background: rgba(64, 158, 255, 0.1);
    border-radius: 0.5 * $space-size-base;
    min-height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2 * $space-size-base;
    cursor: pointer;

    .txt-drop,
    .txt-browse {
      font-style: normal;
      font-weight: normal;
      font-size: $font-size-13;
      line-height: $line-height-rate-base;
      /* identical to box height, or 25px */

      display: flex;
      align-items: flex-end;
      letter-spacing: 0.1px;

      color: #000000;
    }

    .txt-drop {
      margin: 0 $space-size-base;
    }

    .txt-browse {
      color: #007aff;
    }
  }

  .all-files {
    max-height: 200px;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .space-file {
    .file-name {
      font-style: normal;
      font-weight: normal;
      font-size: $font-size-15;
      line-height: $line-height-rate-base;
      /* identical to box height, or 25px */

      letter-spacing: 0.1px;

      /* Text / Primary */

      color: #343434;
    }

    .file-name.error {
      color: #ff3b30;
    }

    .ic-cancel {
      cursor: pointer;
    }
  }
}

.dropdown-select-status,
.dropdown-select-response-status {
  width: 326px !important;
  .ant-select-item-option-content {
    display: flex;
    align-items: center;
    max-height: 30px !important;
  }

  .wrap-response-status {
    margin-right: 8px;
    font-size: $font-size-13;
  }
}

.dropdown-select-response-status {
  .ant-select-item-option-content {
    display: flex;
    align-items: center;
  }
}
