@import 'styles/variables.scss';
.header {
  height: 60px !important;
  background-color: #002e5c;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 $space-size-large !important;
  /*position: fixed;*/
  z-index: 2;
  width: 100%;
  .ant-menu-horizontal {
    .ant-menu-item:active,
    .ant-menu-submenu-title:active {
      background: initial !important;
    }
    .ant-menu-item-active {
      &::after {
        border-bottom: 0 solid #002e5c !important;
      }
      :hover {
        opacity: 0.9;
      }
    }
  }

  .logo-container {
    width: 15%;

    .logo {
      height: 32px;
      width: 150px;
      margin: $space-size-large;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      margin-left: 0;
      /*background-image: url('../public/images/logo-header.svg');*/
    }
  }

  .midle-header-container {
    width: 65%;
  }

  .menu-tab {
    background-color: #002e5c;
    color: white;
    border-bottom: none;

    flex: 1;
    display: flex;
    justify-content: center;

    .wrapper-menu {
      min-width: 100px;
      display: flex;
      justify-content: center;

      .menu-standby {
        display: flex;
        align-items: center;
        height: 60px;
      }
      .menu-standby-active {
        border-bottom: 5px solid #18c9f3;
      }
    }

    .menudefault {
      border-bottom: none;
      color: #ffffff;
    }

    .ant-menu-title-content {
      font-size: $font-size-21;
      line-height: $line-height-rate-micro;
      color: #ffffff;
      font-weight: normal;
    }

    .ant-menu-item-selected {
      &::after {
        border-bottom: none;
      }

      .ant-menu-title-content {
        color: #18c9f3;
      }
    }
  }

  .extra-profile {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .icon {
      .ant-space-item {
        .ic-qs,
        .ic-bell {
          color: #fff;
          font-size: $font-size-20;
          cursor: pointer;
        }
      }
    }

    .pop-up-notifications {
      cursor: pointer;
      .badge-notification {
        .ant-badge-count {
          background: #ff3b30;
          border-radius: 24px;
          height: 24px;
          width: 24px;
          box-shadow: none;
          display: flex;
          justify-content: center;
          align-items: center;

          .ant-scroll-number-only {
            font-style: normal;
            font-weight: bold;
            font-size: $font-size-11;
          }
        }

        .ant-badge-multiple-words {
          font-style: normal;
          font-weight: bold;
          font-size: $font-size-11;
        }
      }
    }

    .divider {
      height: 30px;
      border-left: 2px solid #fff;
    }

    .avatar {
      cursor: pointer;
      .ant-space-item {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 10px;
        max-width: 300px;
        color: #ffffff;
        .ic-avatar {
          background: #f2f2f2;
          border-radius: 100px;

          img {
            object-fit: none;
          }
        }

        .profile-name {
          color: #ffffff;
          font-size: $font-size-17;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 250px;
        }
      }
    }
  }
}

.dropdown-menu-user {
  width: 326px;
  right: 16px;
  left: 0;

  ul {
    padding: 10px 0;
    border-radius: 0.5 * $space-size-base;
  }

  .ant-dropdown-menu-item {
    padding: $space-size-base 1.5 * $space-size-base;
    &:hover {
      background: rgba(64, 158, 255, 0.1);
    }
  }

  .ant-dropdown-menu-item-divider {
    background-color: transparent;
  }

  .txt-menu {
    font-style: normal;
    font-weight: normal;
    font-size: $font-size-15;
    line-height: $line-height-rate-base;
    color: #343434;
  }
}
