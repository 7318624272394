@import 'styles/variables.scss';
@import './components/activation-volume/activation-volume.scss';
@import './components/bulk-incidents/bulk-incidents.scss';

.reports-page {
  display: flex;
  min-height: 100%;
  justify-content: flex-end;
  .body-report-page {
    width: calc(100% - 260px);
  }
  .menu-report {
    padding: 8px 16px;
    max-width: 260px;
    height: calc(100% - 108px);
    position: absolute;
    left: 0px;
    li.ant-menu-item-only-child {
      padding-left: 16px !important;
    }

    .ant-menu-item-group-title {
      font-style: normal;
      font-weight: bold;
      font-size: $font-size-15;
      line-height: $line-height-rate-nano;
      letter-spacing: 0.1px;
      color: #343434;
      padding-left: 0;
    }

    .ant-menu-item-selected {
      border-radius: 0.5 * $space-size-base;
      color: #18c9f3;
      background: #ffffff;
      border: 2px solid #5ac8fa;
    }

    .ant-menu-title-content {
      font-style: normal;
      font-weight: normal;
      font-size: $font-size-15;
      line-height: $line-height-rate-nano;
      letter-spacing: 0.1px;
    }
    .ant-menu-item::after {
      border-right: 0;
    }
  }
  .header-report {
    height: 60px;
    display: flex;
    align-items: center;
    padding: 0;
    background: #f9f9f9 !important;
    box-shadow: inset 0px -1px 0px rgba(144, 147, 153, 0.2);
    z-index: 99;
    width: 100%;
    padding: 0 16px;
    justify-content: flex-end;
  }
  .timeline-filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .timeline-table .row-odd {
    background-color: rgba(144, 147, 153, 0.1);
  }
  .timeline-table .ant-table-tbody > tr > td,
  .reports .timeline .timeline-table .ant-table-tbody > tr > td .space-timeline .date-month,
  .reports .timeline .timeline-table .ant-table-tbody > tr > td .space-timeline .range-date {
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    letter-spacing: 0.1px;
  }
  .timeline .timeline-table .ant-table-tbody > tr > td {
    line-height: 1.5;
    padding: 0 12px;
    height: 48px;
    color: #343434;
  }
  .timeline .timeline-table .ant-table-thead > tr > th {
    font-style: normal;
    font-weight: 400;
    font-size: 0.93333rem;
    line-height: 1.2;
    letter-spacing: 0.1px;
    text-transform: uppercase;
    color: #343434;
    background: #fff;
  }
  .timeline .timeline-filter .range-picker {
    border-radius: 4px;
    width: 375px;
  }
  .timeline .timeline-filter .range-picker .ant-picker-input input {
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    letter-spacing: 0.5px;
  }
  .activity-volume
    .activation-volume-tabs
    .ant-tabs-nav
    .ant-tabs-tab.ant-tabs-tab-active
    .ant-tabs-tab-btn {
    color: #18c9f3 !important;
  }
  .activity-volume .activation-volume-tabs .ant-tabs-nav .ant-tabs-tab-btn {
    font-style: normal;
    font-size: 1.13333rem;
    line-height: 1.5;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.1px;
  }
  .activity-volume .activation-volume-tabs .ant-tabs-nav .ant-tabs-nav-wrap {
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    padding: 0 16px;
  }
  .timeline {
    padding: 12px 16px;
  }
  .activity-volume .activation-volume-tabs .ant-tabs-nav {
    margin-bottom: 0;
  }
  .timeline .timeline-table .ant-table-cell {
    padding: 4px 12px;
  }
  .timeline .timeline-table .ant-table-tbody > tr > td .space-timeline .range-date {
    font-size: 0.86667rem;
  }
  .timeline .timeline-table .row-summary {
    background: rgba(64, 158, 255, 0.1);
  }
  .timeline .timeline-table .row-summary .cell-summary {
    text-align: right;
  }
  .timeline .timeline-table .row-summary td {
    height: 48px;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    color: #343434;
  }
  .btn-export {
    font-style: normal;
    font-size: 1rem;
    line-height: 1.2;
  }
  .bulk-incidents {
    padding: 32px 16px;
  }
  .bulk-incidents .ant-form-item-label {
    font-style: normal;
    font-size: 1rem;
    line-height: 1.2;
    letter-spacing: 0.1px;
    color: #343434;
    padding-bottom: 2px !important;
    display: inline-flex;
  }
  .bulk-incidents .range-picker {
    border-radius: 4px;
    width: 100%;
  }
  .active-by-organization .table-calculate .row-school,
  .active-by-organization .table-organization .row-school {
    background: #a8e8fe;
  }
  .active-by-organization .table-calculate .ant-table-tbody > tr.ant-table-row-level-0:hover > td,
  .active-by-organization .table-calculate .ant-table-tbody > tr.ant-table-row-level-1:hover > td,
  .active-by-organization
    .table-organization
    .ant-table-tbody
    > tr.ant-table-row-level-0:hover
    > td,
  .active-by-organization
    .table-organization
    .ant-table-tbody
    > tr.ant-table-row-level-1:hover
    > td {
    background: unset;
  }
  .active-by-organization .table-calculate .row-org,
  .active-by-organization .table-organization .row-org {
    background: #ffdeeb;
  }
  .active-by-organization .head {
    display: flex;
    align-items: center;
    height: 48px;
    border-bottom: 1px solid rgba(144, 147, 153, 0.2);
    padding: 8px 12px;
  }
  .active-by-organization .head .search-input,
  .active-by-organization .head .date-picker {
    width: 100%;
    border-radius: 4px;
    height: 32px;
  }
  .active-by-organization .ant-col {
    border-right: 1px solid rgba(144, 147, 153, 0.2);
    border-bottom: 1px solid rgba(144, 147, 153, 0.2);
  }
  .active-by-organization .table-calculate .ant-table-thead > tr > th,
  .active-by-organization .table-organization .ant-table-thead > tr > th {
    font-style: normal;
    font-weight: 400;
    font-size: 0.93333rem;
    line-height: 1.2;
    letter-spacing: 0.1px;
    text-transform: uppercase;
    color: #343434;
    background: #fff;
    padding: 16px 6px;
  }
  .active-by-organization .table-calculate .ant-table-thead > tr > th .switch-hidden,
  .active-by-organization .table-organization .ant-table-thead > tr > th .switch-hidden {
    background-color: #ff3b30;
    height: 10px;
    line-height: 10px;
    margin-bottom: 2px;
  }
  .active-by-organization
    .table-calculate
    .ant-table-thead
    > tr
    > th
    .switch-hidden.ant-switch-checked,
  .active-by-organization
    .table-organization
    .ant-table-thead
    > tr
    > th
    .switch-hidden.ant-switch-checked {
    background-color: #34c759;
  }
  .active-by-organization
    .table-calculate
    .ant-table-thead
    > tr
    > th
    .switch-hidden
    .ant-switch-handle,
  .active-by-organization
    .table-organization
    .ant-table-thead
    > tr
    > th
    .switch-hidden
    .ant-switch-handle {
    width: 8px;
    height: 8px;
    top: 1px;
  }
  .active-by-organization .table-calculate .ant-table-tbody > tr > td,
  .active-by-organization .table-organization .ant-table-tbody > tr > td {
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.1px;
    color: #343434;
  }
  .active-by-organization .view-add {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .active-by-organization .view-add .space-view {
    margin-top: 100px;
    max-width: fit-content;
  }
  .active-by-organization .view-add .space-view .txt-add {
    font-style: normal;
    font-weight: 400;
    font-size: 1.13333rem;
    line-height: 1.5;
    letter-spacing: 0.1px;
  }

  .ant-picker .ant-picker-clear,
  .ant-picker .ant-picker-suffix {
    font-size: 1.33333rem;
  }
  .ant-picker-input input {
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    letter-spacing: 0.5px;
  }
  .search-input .ant-input-prefix {
    color: rgba(0, 0, 0, 0.54);
  }
}
